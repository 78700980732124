
import service from '../utils/request.js'


export const systemSeting = (params) => {
	return service({
		url: '/open/sys-config/global-info',
		methods: 'get',
		params
	})
}

// 0.项目地址信息
export const projectRegion = (params) => {
	return service({
		url: '/project-index/projectRegion',
		methods: 'get',
		params
	})
}

// 1.概况
export const overviewConfig = (params) => {
	return service({
		url: '/project-index/getOverviewConfig',
		methods: 'get',
		params
	})
}

// 2.资源及设施分类
export const sourceConfig = (params) => {
	return service({
		url: '/project-index/getSourceConfig',
		methods: 'get',
		params
	})
}
// 2.某具体资源及设施下的点位列表
export const sourceList = (params) => {
	return service({
		url: '/project-source/basicSourceList',
		methods: 'get',
		params
	})
}

// 3. 特产列表
export const specialtyList = (params) => {
	return service({
		url: '/project-specialty/page',
		methods: 'get',
		params
	})
}
// 3. 特产详情
export const specialtyDatail = (params) => {
	return service({
		url: '/project-specialty/detail',
		methods: 'get',
		params
	})
}
// 3. 酒店列表
export const hotelList = (params) => {
	return service({
		url: '/project-hotel/page',
		methods: 'get',
		params
	})
}
// 3. 酒店详情
export const hotelDetail = (params) => {
	return service({
		url: '/project-hotel/detail',
		methods: 'get',
		params
	})
}
// 3. 美食列表
export const foodList = (params) => {
	return service({
		url: '/project-food/page',
		methods: 'get',
		params
	})
}
// 3. 美食详情
export const foodDetail = (params) => {
	return service({
		url: '/project-food/detail',
		methods: 'get',
		params
	})
}

// 4.活动列表
export const activityList = (params) => {
	return service({
		url: '/project-activity/page',
		methods: 'get',
		params
	})
}
// 4.活动详情
export const activityDetail = (params) => {
	return service({
		url: '/project-activity/detail',
		methods: 'get',
		params
	})
}

// 5. 景区列表
export const sceneryList = (params) => {
	return service({
		url: '/project-scenery/sceneryPage',
		methods: 'get',
		params
	})
}
// 5. 景区详情
export const sceneryDetail = (params) => {
	return service({
		url: '/project-scenery/detail',
		methods: 'get',
		params
	})
}
// 5. 景点列表
export const spotList = (params) => {
	return service({
		url: '/project-spot/page',
		methods: 'get',
		params
	})
}
// 5. 景点详情
export const spotDetail = (params) => {
	return service({
		url: '/project-spot/detail',
		methods: 'get',
		params
	})
}

// 6.旅游资讯列表
export const strategyList = (params) => {
	return service({
		url: '/project-news/page',
		methods: 'get',
		params
	})
}
// 6.旅游资讯详情
export const strategyDetail = (params) => {
	return service({
		url: '/project-news/detail',
		methods: 'get',
		params
	})
}
// 6.项目配置信息
export const getConfig = (params) => {
	return service({
		url: '/project-index/getIndexConfig',
		methods: 'get',
		params
	})
}

// 7.获取720全景配置
export const panoramaConfig = (params) => {
	return service({
		url: '/project-index/getPanoramaConfig',
		methods: 'get',
		params
	})
}

// 8.美景美图
export const imageList = (params) => {
	return service({
		url: '/project-media/picturePage',
		methods: 'get',
		params
	})
}
// 8.美景视频
export const videoList = (params) => {
	return service({
		url: '/project-media/videoPage',
		methods: 'get',
		params
	})
}


// 手绘图配置
export const GisConfig = (params) => {
	return service({
		url: "/project-index/getGisConfig",
		methods: "get",
		params
	})
}


// 模板
const BaseUrl = 'https://xxxxxxx'
// 获取用户信息
export function getUserInfo(params) {
	return service({
		// 请求路径
		url: `${BaseUrl}/api/user/updateInfo`,
		// 请求方法
		methods: 'get',
		// 数据（ps：此处使用了es6解构的语法，原写法为  params: params）
		params
	})
}
// 修改用户信息
export function updateUserInfo(data) {
	return service({
		url: `${BaseUrl}/api/user/updateInfo`,
		methods: 'post',
		data
	})
}
// 上传资料文件
export function uploadData(data) {
	return fetch({
		url: `${BaseUrl}/api/user/uploadData`,
		method: 'post',
		// 上传表单文件，请求头中Content-Type设置为multipart/form-data，数据类型为表单上传文件
		headers: {
			'Content-Type': 'multipart/form-data'
		},
		// 在向服务器发送前，修改请求数据
		// 它只能用于 'PUT', 'POST' 和 'PATCH' 这几个请求方法
		// 修改请求头也可以，以上就是修改了请求头，因此此处直接返回即可
		transformRequest(data) {
			// 对发送的data进行转换处理
			return data
		}
	})
}
// 下载文件
export function downloadData(params) {
	return fetch({
		url: `${BaseUrl}/api/user/downloadData`,
		method: 'get',
		params,
		// 设置响应的数据类型，默认为json，其值有：'arraybuffer', 'document', 'json', 'text', 'stream','blob'
		// 后端返回的是数据流时设置为blob；返回的二进制buffer流时，设置为arraybuffer
		responseType: 'blob'
	})
}