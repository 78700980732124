export default {
    // 解密aesKey
    aesKey: "uwTZwrCZYh7zKROK",

    // 察尔汗
    baseUrl: "https://touch.mhyhly.com/api", // 项目请求地址
    app_id: "qoUVUKVpIAHnqULxAOjv", // 项目标识
    projectType: "scenicSpot", // scenicSpot景区版(旅游景点),group集团版(旅游景区)
    screenType: 1, // 1竖屏,0横屏
    adminPassword: "CHEH@123456", // 管理员密码
    prefix: "", // 资源前缀
    renewCode: "CEH_TOUCH", // 当前更新到的版本(只针对桌面端)
    version: "0.0.1",

    // 功能模块
    modular: [
        {
            name: "概况", type: "router", router: "/survey",
            icon: require("@/assets/images/modular/survey.png"),
            icon1: require("@/assets/images/modular/survey-1.png")
        },
        {
            name: "导览", type: "router", router: "/guide",
            icon: require("@/assets/images/modular/guide.png"),
            icon1: require("@/assets/images/modular/guide-1.png")
        },
        {
            name: "游购", type: "router", router: "/travelshop",
            icon: require("@/assets/images/modular/tourist.png"),
            icon1: require("@/assets/images/modular/tourist-1.png")
        },
        {
            name: "精彩活动", type: "router", router: "/activity",
            icon: require("@/assets/images/modular/activity.png"),
            icon1: require("@/assets/images/modular/activity-1.png")
        },
        {
            name: "旅游景点", type: "router", router: "/spot",
            icon: require("@/assets/images/modular/spot.png"),
            icon1: require("@/assets/images/modular/spot-1.png")
        },
        {
            name: "旅游资讯", type: "router", router: "/info",
            icon: require("@/assets/images/modular/info.png"),
            icon1: require("@/assets/images/modular/info-1.png")
        },
        {
            name: "在线购票", type: "model", router: "/panorama",
            icon: require("@/assets/images/modular/panorama.png"),
            icon1: require("@/assets/images/modular/panorama-1.png")
        },
        {
            name: "视频图库", type: "router", router: "/media",
            icon: require("@/assets/images/modular/media.png"),
            icon1: require("@/assets/images/modular/media-1.png")
        }
    ],
    // 功能模块补充
    modularSupplement: [
        { name: "返回首页", type: "router", icon1: require("@/assets/images/modular/home-1.png"), router: "/" },
        { name: "退出系统", type: "router", icon1: require("@/assets/images/modular/quit-1.png"), router: "/quit" }
    ],

    // 高德地图web端key
    gaodeWebKey: "a2e5217de3ad677eec70c599212f81e8",

    // 分页默认参数
    paging: {
        page: 1,
        limit: 12
    }
}