<!--
 * @Author: ranjianglai
 * @Date: 2022-11-01 17:48:59
 * @Description: 首页
-->


<template>
	<div class="home">
		<!-- Banner -->
		<b-carousel id="carousel" :interval="5000" background="#f7f8f9" indicators>
			<b-carousel-slide v-for="(item, index) in carousels" :key="index" :img-src="item"></b-carousel-slide>
		</b-carousel>
		<!-- Menu -->
		<b-container class="bv-example-row">
			<b-row>
				<b-col class="col" lg="3" v-for="(item, index) in modular" :key="index">
					<router-link v-if="item.type == 'router'" :to="item.router" class="item">
						<b-img class="icon" :src="item.icon" :alt="item.name"></b-img>
						<span>{{ item.name }}</span>
					</router-link>
					<div v-else-if="item.type == 'model'" class="item" @click="$bus.$emit('showTicketingModel')">
						<b-img class="icon" :src="item.icon" :alt="item.name"></b-img>
						<span>{{ item.name }}</span>
					</div>
					<a v-else :href="item.link" class="item">
						<b-img class="icon" :src="item.icon" :alt="item.name"></b-img>
						<span>{{ item.name }}</span>
					</a>
				</b-col>
			</b-row>
		</b-container>
		<!-- Quit -->
		<router-link to="/quit" class="quit">
			<b-img class="img" :src="require('@/assets/images/quit.png')"></b-img>
		</router-link>
		<!-- Logo -->
		<img class="home-logo" :src="require('@/assets/images/homeLogo.png')" alt="">
	</div>
</template>


<script>
import { projectRegion } from '/src/api/index.js'
export default {
	name: 'Home',
	components: {},
	props: {},

	data() {
		return {
			carousels: [
				require('@/assets/images/home/banner.png'),
				// require('@/assets/images/home/banner2.png'),
				// require('@/assets/images/home/banner3.png')
			],
			modular: this.$config.modular
		}
	},

	computed: {},
	watch: {},

	created() { },
	mounted() {
		projectRegion().then(res => {
			console.log('项目地址', res)
			sessionStorage.setItem('lat', res.lat)
			sessionStorage.setItem('lng', res.lng)
		}).catch(err => { })
	}
}
</script>


<style lang="scss">
.img-fluid {
	height: 100vh !important;
	object-fit: cover;
}
</style>
<style scoped lang="scss">
.bv-example-row {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	align-items: center;

	.col {
		margin: 4rem 0;
		display: flex;
		justify-content: center;

		.item {
			cursor: pointer;
			position: relative;

			.icon {
				width: 18rem;
				height: 18rem;
			}

			span {
				font-size: 2rem;
				position: absolute;
				top: 72%;
				left: 50%;
				transform: translate(-50%, -50%);
				color: #fff;
			}
		}
	}
}

.quit {
	position: fixed;
	right: 5rem;
	bottom: 9rem;
	z-index: 100;

	.img {
		width: 3rem;
		height: 3rem;
	}
}

.home-logo {
	width: 42rem;
	position: fixed;
	top: 5.8rem;
	left: 7rem;
}</style>