<template>
    <div class="footer">
        <b-container>
            <b-row>
                <b-col cols="6" sm="4" md="3" lg="2" class="col" v-for="(item, index) in modular" :key="index">
                    <router-link v-if="item.type == 'router'" :to="item.router"
                        :class="['item', item.router == path ? 'item-active' : null]">
                        <b-img class="icon" :src="item.icon1" :alt="item.name"></b-img>
                        <span>{{ item.name }}</span>
                    </router-link>
                    <div v-else-if="item.type == 'model'" class="item" @click="$bus.$emit('showTicketingModel')">
                        <b-img class="icon" :src="item.icon1" :alt="item.name"></b-img>
                        <span>{{ item.name }}</span>
                    </div>
                    <a v-else :href="item.link" class="item">
                        <b-img class="icon" :src="item.icon1" :alt="item.name"></b-img>
                        <span>{{ item.name }}</span>
                    </a>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
export default {
    name: 'Footer',
    components: {},
    props: {
        path: { type: String, default: '' }
    },

    data() {
        return {
            modular: this.$config.modularSupplement.concat(this.$config.modular)
        }
    },

    computed: {},
    watch: {},

    created() { },
    mounted() { },

    methods: {}
}
</script>


<style>
.footer .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
</style>
<style scoped lang="scss">
.footer {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(5px);
    padding: 2rem;

    .col {
        .item {
            margin: 1rem 0;
            padding: 2rem 0;
            background: rgba(0, 0, 0, .35);
            border-radius: 0.6rem;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.8rem;
            color: #FFF;
            text-decoration: none;
            transition: background .3s;

            .icon {
                width: 2.2rem;
                height: 2.2rem;
                margin-right: 1rem;
            }

            &:hover {
                background: hsla(0, 0%, 100%, .4);
            }

            &-active {
                background: hsla(0, 0%, 100%, .4);
            }
        }
    }
}
</style>
