import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import IndexView from "../views/IndexView.vue";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "home",
		component: Home
	},

	{
		path: "/index",
		component: IndexView,
		children: [
			{
				path: "/",
				redirect: "/survey"
			},
			{
				path: "/survey", 
				name: "survey",
				meta: {
					title: "概况"
				},
				component: () => import("../views/Survey.vue")
			},
			{
				path: "/guide", 
				name: "guide",
				meta: {
					title: "导览"
				},
				component: () => import("../views/Guide.vue")
			},
			{
				path: "/travelshop",
				name: "travelshop",
				meta: {
					title: "游购"
				},
				component: () => import("../views/TravelShop.vue")
			},
			{
				path: "/activity",
				name: "activity",
				meta: {
					title: "精彩活动"
				},
				component: () => import("../views/Activity.vue")
			},
			{
				path: "/spot",
				name: "spot",
				meta: {
					title: "景区景点"
				},
				component: () => import("../views/Spot.vue")
			},
			{
				path: "/info",
				name: "info",
				meta: {
					title: "旅游资讯"
				},
				component: () => import("../views/Info.vue")
			}
		]
	},

	{
		path: "/panorama",
		name: "panorama",
		meta: {
			title: "全景漫游"
		},
		component: () => import("../views/Panorama.vue")
	},

	{
		path: "/media",
		name: "media",
		meta: {
			title: "视频图库"
		},
		component: () => import("../views/Media.vue")
	},

	{
		path: "/quit",
		name: "quit",
		meta: {
			title: "退出"
		},
		component: () => import("../views/Quit.vue")
	}
]

const router = new VueRouter({
	mode: "hash",
	base: process.env.BASE_URL,
	routes
})

export default router;