<!--
 * @Author: ranjianglai
 * @Date: 2022-11-01 17:48:59
 * @Description: 入口文件
-->


<template>
    <div id="app">
        <el-backtop target="#app" :bottom="90"></el-backtop>
        <router-view />
        <div v-if="ticketingModel" class="model" @click.stop="ticketingModel = false">
            <img class="code" :src="require('@/assets/images/modular/Code.png')" alt="" @click.stop="ticketingModel = true">
        </div>
        <!-- 返回 -->
        <div v-if="$config.screenType == 0 && $route.path != '/'" class="back" @click="back">
            <i class="el-icon-back"></i>
        </div>
    </div>
</template>


<script>
import axios from "axios";
export default {
    data() {
        return {
            ticketingModel: false
        }
    },

    mounted() {
        // 屏幕切换
        if (document.body.clientWidth > document.body.clientHeight) { // 横屏
            this.$config.screenType = 0;
        } else {
            this.$config.screenType = 1;
        }
        window.addEventListener("orientationchange", () => {
            if (window.orientation === 0) { // 横屏
                this.$config.screenType = 0;
            }
            if (window.orientation === 90) {
                this.$config.screenType = 1;
            }
            window.onload();
        }, false)

        // 兼容ios禁止缩放
        try {
            document.addEventListener("touchstart", function (event) {
                if (event.touches.length > 1) {
                    event.preventDefault();
                }
            })
            document.addEventListener("gesturestart", function (event) {
                event.preventDefault();
            })
        } catch (error) { }

        // 检查更新 http://139.9.237.1:19993
        axios.get("https://app.smarttourisms.com/api/app-info/find/" + this.$config.renewCode).then(res => {
            const url = res.data.data.url;
            const number = url.lastIndexOf("/");
            const updateUrl = url.slice(0, number + 1);
            // const updateUrl = "http://192.168.1.166:8000/package/"; // 本地服务器调试
            console.log("检查更新", updateUrl);

            window.ipcRenderer.send("set-download-url", { url: updateUrl });
            setTimeout(() => {
                window.ipcRenderer.send("ev-check-for-update");
            }, 500)
            window.ipcRenderer.on("ev-message", (event, message) => {
                this.$notify({
                    title: "提示",
                    message,
                });
            });
            window.ipcRenderer.on("ev-should-update", (event, message) => {
                this.$confirm("检查到新版本，是否更新?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                    showCancelButton: true,
                    showClose: false,
                    closeOnClickModal: false,
                }).then(() => {
                    window.ipcRenderer.send("ev-update-now");
                }).catch(() => { })
            });
        })

        // 在线购票
        this.$bus.$on("showTicketingModel", data => {
            this.ticketingModel = true;
        });
    },

    methods: {
        back() {
            this.$router.back();
        }
    }
}
</script>


<style lang="scss">
html {
    font-size: 10px;
}

:root {
    --rjl-acive: #ff7800;
}

#app {
    font-family: "黑体", Regular, "思源黑体";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #000;
    width: 100vw;
    height: 100vh;
    font-size: 1.6rem;
    overflow-x: hidden;

    &::-webkit-scrollbar {
        display: none;
    }
}

@import "/src/assets/css/common.css";

p {
    margin: 0 !important;
}

.no-copy {
    -moz-user-select: none;
    /* Firefox私有属性 */
    -webkit-user-select: none;
    /* WebKit内核私有属性 */
    -ms-user-select: none;
    /* IE私有属性(IE10及以后) */
    -khtml-user-select: none;
    /* KHTML内核私有属性 */
    -o-user-select: none;
    /* Opera私有属性 */
    user-select: none;
    /* CSS3属性 */
}

/* 毛玻璃卡片 */
.ground-glass-card {
    width: 100%;
    margin: 6rem 0 8rem;
    border-radius: 1rem;
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(5px);
    background-attachment: fixed !important;
    padding: 4rem !important;
    flex: 1;
    overflow: auto;

    &::-webkit-scrollbar {
        display: none;
    }
}

/* lodaing */
.el-loading-mask {
    background: transparent !important;
    display: flex;
    justify-content: center;
    font-family: "黑体", Regular, "思源黑体";

    .el-loading-spinner {
        border-radius: 6px;
        padding: 12px;
        background: rgba(0, 0, 0, 0.7) !important;
        width: 160px;

        .el-loading-text {
            margin-top: 6px !important;
            text-align: center;
        }
    }
}

/* 在线购票 */
.model {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
    .code {
        // width: 40rem;
        height: 66rem;
        border-radius: 1rem;
    }
}

/* 返回 */
.back {
    position: fixed;
    top: 8vh;
    left: 6vw;
    z-index: 1000;
    background: rgba(255, 255, 255, 0.5);
    background: #fff;
    opacity: 0.5;
    border-radius: 50%;
    padding: 0.5rem;
    transition: all 0.2s;
    cursor: pointer;
    &:hover {
        opacity: 1;
    }
    .el-icon-back {
        font-size: 3rem;
    }
}

/** 游购、精彩活动、旅游景点、旅游资源数据为空 */
.app-empty {
    width: 100%;
    min-height: 40vh;
    margin: 3rem 0;
    border-top: 1px dashed #939492;
    border-bottom: 1px dashed #939492;
    padding: 3rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    span {
        margin-top: 1rem;
    }
}

/* 兼容后台富文本编辑器返回的table样式 */
table {
    height: auto !important;
}
table tr,
table th,
table td {
    border: 1px solid rgba(0, 0, 0, 0.3);
    border: none;
    height: auto !important;
    text-align: center;
    padding: 5px 3px;
}
table img {
    margin: 0 !important;
    max-width: 100% !important;
}
</style>