import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import "./router/router.permission"
import store from "./store"


// BootstrapVue
import BootstrapVue from "bootstrap-vue"
import "bootstrap/dist/css/bootstrap.css"
import "bootstrap-vue/dist/bootstrap-vue.css"
Vue.use(BootstrapVue)


// ant-design-vue
import Antd from "ant-design-vue"
import "ant-design-vue/dist/antd.css"
Vue.use(Antd)


// ElementUI
import ElementUI from "element-ui"
import "element-ui/lib/theme-chalk/index.css"
Vue.use(ElementUI)


// 全局配置
import config from "./config/index.js"
Vue.prototype.$config = config

// 创建全局事件总线,在Vue原型上定义全局事件总线
const Bus = new Vue();
Vue.prototype.$bus = Bus;


// 高德地图绘制区域
window._AMapSecurityConfig = {
	securityJsCode: "8e3ab3e836d4ef4e894fc796c4ee38e8"
}


// 生产环境全局取消console.log
if (process.env.NODE_ENV !== "development") {
	console.log = function () { }
	console.error = function () { }
}


Vue.config.productionTip = false


new Vue({
	router,
	store,
	render: h => h(App)
}).$mount("#app")