<template>
	<div class="index" 
		:style="{
			background: 'url(' + require('@/assets/images/index-bg.png') + ') no-repeat center center',
			height: $config.screenType == 1 ? '100vh' : 'auto'
		}" 
		@click.stop="fixedMenu = false">
		<b-img center style="max-width: 50%; margin-top: -8rem" 
			:src="require('@/assets/images/textLogo.png')"
			alt="logo">
		</b-img>
		<router-view />
		<Footer :path="path" />
		<!-- 横屏菜单 -->
		<div v-if="$config.screenType == 0" class="fixed-menu">
			<i class="el-icon-menu" @click.stop="fixedMenu = !fixedMenu"></i>
			<div class="circle" :style="{ top: !fixedMenu ? '100vh' : 'calc(10vh + 4rem)' }">
				<div v-for="(item, index) in modular" :key="index">
					<router-link v-if="item.type == 'router'" :to="item.router"
						:class="['item', item.router == path ? 'item-active' : null]">
						<b-img class="icon" :src="item.icon1" :alt="item.name"></b-img>
						<span>{{ item.name }}</span>
					</router-link>
					<div v-else-if="item.type == 'model'" class="item" @click="$bus.$emit('showTicketingModel')">
						<b-img class="icon" :src="item.icon1" :alt="item.name"></b-img>
						<span>{{ item.name }}</span>
					</div>
					<a v-else :href="item.link" class="item">
						<b-img class="icon" :src="item.icon1" :alt="item.name"></b-img>
						<span>{{ item.name }}</span>
					</a>
				</div>
			</div>
		</div>
	</div>
</template>


<script>
import Footer from "@/components/Footer.vue";
export default {
	name: "IndexView",
	components: {
		Footer
	},
	props: {},

	data() {
		return {
			path: "",
			modular: this.$config.modularSupplement.concat(this.$config.modular),
			fixedMenu: false,
		}
	},

	watch: {
		$route: "getPath"
	},

	mounted() {
		this.getPath();
	},

	methods: {
		getPath() {
			this.path = this.$route.path;
		}
	}
}
</script>


<style scoped lang="scss">
.index {
	height: 100vh;
	background-size: cover !important;
	background-attachment: fixed;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 8rem;
	padding-bottom: 20rem;
}

.fixed-menu {
	position: fixed;
	top: 8vh;
	right: 6vw;
	z-index: 1000;
	display: flex;
	flex-direction: column;
	align-items: flex-end;

	.el-icon-menu {
		font-size: 4rem;
		cursor: pointer;
	}

	.circle {
		position: fixed;
		top: calc(8vh + 4rem);
		right: 8vw;
		border-radius: .5rem;
		overflow: hidden;
		transition: all .3s;

		.item {
			background: rgba(0, 0, 0, .35);
			display: flex;
			align-items: center;
			cursor: pointer;
			padding: 1rem;
			color: #fff;

			.icon {
				width: 2.2rem;
				height: 2.2rem;
				margin-right: .5rem;
			}

			span {
				font-size: 1.5rem;
			}

			&:hover {
				background: hsla(0, 0%, 100%, .4);
			}

			&-active {
				background: hsla(0, 0%, 100%, .4);
			}
		}
	}
}
</style>