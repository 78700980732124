import router from "./index";
import { systemSeting } from "@/api/index.js";
import config from "@/config/index";
import { AesEncrypt } from "@/utils/encryption";

router.beforeEach((to, from, next) => {
    if(sessionStorage.getItem("SYSTEMSETINFO")){
        next();
    }else{
        console.log(2)
        // 获取项目配置
        systemSeting().then(res=>{
            sessionStorage.setItem("SYSTEMSETINFO", AesEncrypt(JSON.stringify(res),config.aesKey));
            next();
        })
    }
})